<template>
  <v-app>
    <v-app-bar app color="rojo" dark>
      <section class="d-flex align-center">
        <a href="/">
          <v-img
            alt="Intituto de los Mexicanos en el Exterior"
            class="shrink mr-6"
            contain
            min-width="100"
            src="https://ime.gob.mx/images/ime-mx.png"
            width="100"
          />
        </a>
        <h1 class="hidden-sm-and-down white--text text-h5 text-shadow">
          {{ path.app_name }}
        </h1>
      </section>

      <v-spacer></v-spacer>

      <MenuRolesComponent v-if="currentUser" />

      <GlobalPublicMenuComponent v-if="!currentUser" />
    </v-app-bar>

    <v-main class="mb-10">
      <v-container>
        <router-view />
      </v-container>
    </v-main>

    <Footer></Footer>
  </v-app>
</template>

<script>
import GlobalPublicMenuComponent from "@/components/global/_PublicMenu.vue";
import MenuRolesComponent from "@/components/roles/MenuRoles.vue";
import Footer from "@/components/global/Footer.vue";
export default {
  name: "App",

  components: {
    GlobalPublicMenuComponent,
    MenuRolesComponent,
    Footer,
  },

  data: () => ({
    path: {
      app_name: process.env.VUE_APP_NAME,
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),

  computed: {
    /* Comprobamos el inicio de la sesion por medio del Store */
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  mounted() {},

  methods: {},
};
</script>
