import http from '@/services/http-common';

class wsPublicArea{

  loadPublicArea() {
    return http.get("/public-menu");
  }



}

export default new wsPublicArea();