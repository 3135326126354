<template>
  <v-footer color="verde" padless fixed class="footer-glass white--text ">
    <v-col
      class="text-center text-overline light"
      cols="12"
    >
      &copy;
      <strong class="hidden-sm-and-down">Instituto de los Mexicanos en el Exterior</strong>
      <strong class="hidden-md-and-up">IME</strong>
      {{ new Date().getFullYear() }}
    </v-col>
  </v-footer>
</template>
<script>
export default {
  name: 'FooterComponent'  
}
</script>