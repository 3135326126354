import Vue from "vue";
import VueRouter from "vue-router";
//import HomeView from '../views/HomeView.vue'

/*
 * Area Publica
 */
import Home from "@/views/Home.vue";
import Login from "@/views/auth/Login.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import EmailConfirmation from "@/views/auth/EmailConfirmation.vue";
import Register from "@/views/auth/Register.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Login,
  },

  /* Auth */
  {
    path: "/auth/login",
    component: Login,
  },
  {
    path: "/auth/register",
    component: Register,
  },
  {
    path: "/auth/reset-password",
    name: "AuthResetPassword",
    component: ResetPassword,
  },
  {
    path: "/auth/email-confirmation/:token?",
    name: "EmailConfirmation",
    component: EmailConfirmation,
  },

  /* User > Profile */
  {
    path: "/user/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "perfil" */ "@/views/auth/Profile.vue"),
  },

  /* Convocatorias */
  {
    path: "/convocatoria/:uuid",
    name: "Convocatorias",
    component: () =>
      import(
        /* webpackChunkName: "convocatorias" */ "@/views/convocatorias/List.vue"
      ),
  },
  {
    path: "/registro/:uuid",
    name: "Registros",
    component: () =>
      import(
        /* webpackChunkName: "registro" */ "@/views/convocatorias/Show.vue"
      ),
  },

  //{
  //  path: "/registros/",
  //  name: "RegistrosRme",
  //  component: () => import("@/views/convocatorias/Registers.vue"),
  //},

  /* Regiones */
  {
    path: "/region/:region/:category",
    name: "RegionesCategorias",
    component: () =>
      import(/* webpackChunkName: "region" */ "@/views/region/Region.vue"),
  },

  /* Favoritos */
  {
    path: "/seleccionados",
    name: "Favoritos",
    component: () =>
      import(
        /* webpackChunkName: "seleccionados" */ "@/views/favoritos/All.vue"
      ),
  },
  {
    path: "/seleccionados/:region/:category",
    name: "FavoritosRegionesCategorias",
    component: () =>
      import(/* webpackChunkName: "region" */ "@/views/favoritos/Region.vue"),
  },

  {
    path: "/categoria/:region/:category",
    name: "CategoriasRegiones",
    component: () =>
      import(
        /* webpackChunkName: "categoria" */ "@/views/categorias/Region.vue"
      ),
  },
  /* Seleccionados */
  {
    path: "/finalistas",
    name: "DashboardFinalistas",
    component: () =>
      import(
        /* webpackChunkName: "lista-finalistas" */ "@/views/categorias/Finalistas.vue"
      ),
  },

  {
    path: "/finalista/:region",
    name: "FinalistaId",
    component: () =>
      import(
        /* webpackChunkName: "finalista-id" */ "@/views/categorias/Ganadores.vue"
      ),
  },

  /* Dashboard */
  {
    path: "/dashboard",
    name: "PanelRegistro",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
  },

  /*
   *
   * Literatura
   *
   */
  {
    path: "/obra/:uuid",
    name: "RegistroObra",
    component: () =>
      import(
        /* webpackChunkName: "literatura" */ "@/views/literatura/Show.vue"
      ),
  },

  /*
   *
   * Cine Migrante
   *
   */
  {
    path: "/film/:uuid",
    name: "RegistroCineMigrante",
    component: () =>
      import(
        /* webpackChunkName: "cinemigrante" */ "@/views/cinemigrante/Show.vue"
      ),
  },

  /*
   *
   * Jornada de Artistas
   *
   */
  {
    path: "/jornada/:uuid",
    name: "RegistroJornada",
    component: () =>
      import(
        /* webpackChunkName: "jornada-artistas" */ "@/views/JornadaDeArtistas/Show.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/",
    "/auth/login",
    "/auth/register",
    "/auth/reset-password",
    "/auth/email-confirmation",
  ];
  const authRequired = !publicPages.includes(to.path);

  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    next("/auth/login");
  } else {
    next();
  }
});

export default router;
