<template>
  <section class="public-menu">
    <v-btn to="/auth/register" text class="mr-1">
      <v-icon>mdi-account</v-icon> Registrate
    </v-btn>
    <v-btn to="/auth/login" text class="mr-1">
      <v-icon>mdi-account-arrow-right</v-icon> Iniciar sesión
    </v-btn>
	</section>
</template>
<script>

export default {
  name: 'GlobalPublicMenuComponent',
  
  components: {
  },

  data: () => ({

  }),

  mounted(){

  },

  computed: {

  },

  methods:{

  },

}
</script>