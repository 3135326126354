<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="display-2">{{ content.title }}</h1>
        <hr class="red">
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <section>
          {{ content.description }}
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import wsPublicArea from '@/services/public-area';

export default {
  name: 'Home',
  data() {
    return {
      content: ''
    };
  },
  mounted() {
    
    wsPublicArea.loadPublicArea().then(
      response => {
        this.content = response.data.data;
      },
      error => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );

  }
};
</script>
